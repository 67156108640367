import React from 'react';
import { Link } from 'react-router-dom';
import { CategoryData } from '../types/types';
import usePathSegments from '../hooks/usePathSegments';
import { useHeaderTitle } from './HeaderTitleContext';

interface CategoryDisplayProps {
  category: CategoryData;
}

const CategoryDisplay: React.FC<CategoryDisplayProps> = ({ category }) => {
  const { pathSegments, lastSegment, targetSlug } = usePathSegments(); // Use the hook
  const { setHeaderTitle } = useHeaderTitle();

  const getRelativePath = (url: string) => {
    //if url is null
    if (!url) return 'https://fatimaalqadiri.com/';

    // If the url host contains fatimaalqadiri.com, return the pathname
    if (new URL(url).host.includes('fatimaalqadiri.com')) {
      return new URL(url).pathname;  
    }

    return url;
  };

  const getItemStatus = (item: any) => {
    if (item.slug === lastSegment) {
      return 'active';
    } else if (pathSegments.includes(item.slug)) {
      return 'past-active';
    }

    return '';
  }

  return (
    <div>
      {/* Render subcategories if they exist */}
      {category.children?.nodes && category.children.nodes.length > 0 && (
        <ul>
        {category.children.nodes.map(subcat => (
          <li key={subcat.id} className={`finder__item finder__item-generic-folder ${getItemStatus(subcat)}`}>
            {/* Use Link for client-side routing */}
            <Link to={getRelativePath(subcat.link)} onClick={() => setHeaderTitle(subcat.name)}>
              {subcat.name}
            </Link>
          </li>
        ))}
        </ul>
      )}

      {/* Render posts if they exist */}
      {category.posts?.nodes && category.posts.nodes.length > 0 && (
        <ul>
        {category.posts.nodes.map(post => (
          <li key={post.id} className={`finder__item finder__item-file finder__item-file-${post.postFileType.nodes[0]?.slug} ${getItemStatus(post)}`}>
            <Link to={getRelativePath(post.link)}>
              {post.title}
            </Link>
          </li>
        ))}
        </ul>
      )}
    </div>
  );
};

export default CategoryDisplay;
