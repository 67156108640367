import { useEffect } from 'react';

const useExternalLinksNewTab = () => {
  useEffect(() => {
    const handleExternalLinks = (event) => {
      // Ensure the clicked element is a link with an href attribute.
      const target = event.target.closest('a[href]');
      if (!target) return;

      const href = target.getAttribute('href');
      const isExternal = href && !href.startsWith(window.location.origin) && !href.startsWith('/');

      if (isExternal) {
        // Prevent default behavior
        event.preventDefault();
        // Open the link in a new tab
        window.open(href, '_blank', 'noopener,noreferrer');
      }
    };

    // Add event listener to the document
    document.addEventListener('click', handleExternalLinks);

    // Cleanup function to remove the event listener
    return () => document.removeEventListener('click', handleExternalLinks);
  }, []);
};

export default useExternalLinksNewTab;