// useDocumentTitle.ts (can be placed in your hooks directory)
import { useEffect } from 'react';
import { useHeaderTitle } from '../components/HeaderTitleContext';

const useDocumentTitle = () => {
  const { headerTitle } = useHeaderTitle();

  useEffect(() => {
    // Default title can be handled here
    const defaultTitle = 'Fatima Al Qadiri';
    document.title = headerTitle + ' | ' + defaultTitle || defaultTitle;
  }, [headerTitle]);
};

export default useDocumentTitle;