
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

//console.log("GraphQL URI:", process.env.REACT_APP_GRAPHQL_URI);

const client = new ApolloClient({
  link: new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI }),
  cache: new InMemoryCache(),
});

export default client;
