import React from 'react';
import { useQuery } from '@apollo/client';
import { CategoryData, QueryData, PostData } from '../types/types';
import { GET_DEEP_CATEGORY_BY_SLUG, GET_POST_BY_SLUG } from '../graphql/categoriesQuery';

import CategoryDisplay from './CategoryDisplay';
import PostDisplay from './PostDisplay';
import usePathSegments from '../hooks/usePathSegments';

const CategoryPage: React.FC = () => {
  const { pathSegments, topLevelSlug } = usePathSegments();
  const isPostPath = pathSegments.includes('file'); // Adjust this as needed to detect post paths
  const postSlug = isPostPath ? pathSegments[pathSegments.length - 1] : null;

  const { loading: categoryLoading, error: categoryError, data: catData } = useQuery(GET_DEEP_CATEGORY_BY_SLUG, {
    variables: { slug: topLevelSlug }
  });

  const { data: postData } = useQuery<{ post: PostData }>(GET_POST_BY_SLUG, {
    variables: { slug: postSlug },
    skip: !postSlug,
  });

  // Recursive function to find the category data for each segment
  const findCategoryData = (segments: string[], category: CategoryData | undefined): CategoryData | undefined => {
    // If we are at the top level or no category is defined, return the category as is
    if (segments.length <= 1 || !category) {
      return category;
    }
  
    // Start recursion from the second segment, as the first segment ('music' or 'visual') is already the currentCategory
    const nextSegment = segments[1];
    const nextCategory = category.children?.nodes.find(cat => cat.slug === nextSegment);
    
    // Continue recursion with the next segment
    return findCategoryData(segments.slice(1), nextCategory);
  };
  
  // Render the categories
  return (
    <>
      
      {pathSegments.map((segment, index) => {
        // Check if the segment is 'file', which indicates the next segment is a post slug
        if (segment === 'file' && postData && postData.post) {
          return (
            <PostDisplay post={postData.post} key={index} />
          );
        } else if ( pathSegments[index-1] !== 'file' ) {
          const categoryData = findCategoryData(pathSegments.slice(0, index + 1), catData?.category);

          return (
            <div key={index} className={`flex-none column column-${index}`}>
              {categoryData && <CategoryDisplay category={categoryData} />}
            </div>
          );
        }
      })}
    
    </>
  );
};

export default CategoryPage;