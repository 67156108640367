import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient'; // Adjust the path as necessary
import Launcher from './components/Launcher';
import { HeaderTitleProvider } from './components/HeaderTitleContext';
import useExternalLinksNewTab from "./hooks/useExternalLinksNewTab"
import './index.scss';

function App() {
  useExternalLinksNewTab();

  return (
    <HeaderTitleProvider>
      <ApolloProvider client={client}>
        <Launcher />
      </ApolloProvider>
    </HeaderTitleProvider>
  );
}

export default App;
