import { useLocation } from 'react-router-dom';

const usePathSegments = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const topLevelSlug = pathSegments[0] || 'default'; // Replace 'default' with a default value you prefer
  const lastSegment = pathSegments[pathSegments.length - 1];
  
  // Determine the "current folder" segment
  const targetSlug = pathSegments.includes('file') ? 
                     pathSegments[pathSegments.indexOf('file') - 1] : 
                     pathSegments[pathSegments.length - 1];

  return { pathSegments, topLevelSlug, lastSegment, targetSlug };
};

export default usePathSegments;
