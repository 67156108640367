// In PostDisplay.tsx
import React, { useState, useEffect } from 'react';

import ReactDOM from 'react-dom';
import { PostData } from '../types/types';

interface PostDisplayProps {
  post: PostData;
}

const PostDisplay: React.FC<PostDisplayProps> = ({ post }) => {
  // State to manage the visibility of the large image overlay
  const [isImageOverlayVisible, setImageOverlayVisible] = useState(false);

  // Define the state to hold either a DOMRect object or null
  const [imageRect, setImageRect] = useState<DOMRect | null>(null);

  const handleImageClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    // Now TypeScript knows event.target is an HTMLImageElement
    const rect = (event.target as HTMLImageElement).getBoundingClientRect();
    setImageRect(rect);
    setImageOverlayVisible(true);
  };

  const handleCloseOverlay = () => {
    setImageOverlayVisible(false);
  };

  useEffect(() => {
    if (isImageOverlayVisible && imageRect) {
      const timer = setTimeout(() => {
        // Use type assertion to ensure the correct type
        const overlayImg = document.querySelector('.overlay-img') as HTMLElement | null;
  
        if (overlayImg) { // Check if the element exists to avoid "Object is possibly 'null'"
          overlayImg.style.transform = 'scale(3)'; // Safely to access 'style'
        }
      }, 50); // Short delay to ensure initial style is applied first
  
      return () => clearTimeout(timer);
    }
  }, [isImageOverlayVisible, imageRect]);
  
  
  return (
    <article className='column column__asset flex-none'>
      <h2 className='hidden'>{post.title}</h2>
      {post.featuredImage && (
        <img
          src={post.featuredImage.node?.mediumSize}
          alt={post.title}
          className='img__featured mb-4 cursor-pointer' 
          onClick={handleImageClick}
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
      {post.moreInfoUrl && (
        <div className='clear-both text-center pt-4'>
          <a href={post.moreInfoUrl} target='_blank' rel='noreferrer' className='btn__info'>
            More info&hellip;
          </a>
        </div>
      )}

      {/* Overlay for displaying the full-size image */}
      {isImageOverlayVisible && document.getElementById('portal-root') && ReactDOM.createPortal(
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center cursor-pointer"
          onClick={handleCloseOverlay}
        >
          <img
            src={post.featuredImage.node?.fullSize}
            alt={post.title} 
            className="max-w-full max-h-full overlay-img"
            style={{
              // Use optional chaining to safely access properties of imageRect
              transition: 'transform 0.15s ease-in',
              transform: 'scale(1)', // Start with the original size
              width: imageRect?.width ?? 'auto', // Fallback to 'auto' if imageRect is null
              height: imageRect?.height ?? 'auto',
              top: imageRect?.top ?? 0,
              left: imageRect?.left ?? 0,
            }}
          />
        </div>,
        document.getElementById('portal-root')!
      )}


    </article>
  );
};

export default PostDisplay;
