import { gql } from '@apollo/client';

export const GET_DEEP_CATEGORY_BY_SLUG = gql`
  query GetDeepCategoryBySlug($slug: ID!) {
    category(id: $slug, idType: SLUG) {
      id
      name
      link
      slug
      children(where: {orderby: NAME, order: ASC}) {
        nodes {
          id
          name
          link
          slug
          posts(where: {orderby: {field: TITLE, order: ASC}}) {
            nodes {
              id
              title
              link
              slug
              postFileType: terms(where: { taxonomies: POSTFILETYPE }) {
                nodes {
                  slug
                }
              }
            }
          }
          children(where: {orderby: NAME, order: ASC}) {
            nodes {
              id
              name
              link
              slug
              posts(where: {orderby: {field: TITLE, order: ASC}}) {
                nodes {
                  id
                  title
                  link
                  slug
                  postFileType: terms(where: { taxonomies: POSTFILETYPE }) {
                    nodes {
                      slug
                    }
                  }
                }
              }
              children(where: {orderby: NAME, order: ASC}) {
                nodes {
                  id
                  name
                  link
                  slug
                  posts(where: {orderby: {field: TITLE, order: ASC}}) {
                    nodes {
                      id
                      title
                      link
                      slug
                      postFileType: terms(where: { taxonomies: POSTFILETYPE }) {
                        nodes {
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

`;

export const GET_POST_BY_SLUG = gql`
  query GetPostBySlug($slug: ID!) {
    post(id: $slug, idType: SLUG) {
      id
      title
      content
      slug
      moreInfoUrl
      featuredImage {
        node {
          mediumSize: sourceUrl(size: MEDIUM)
          fullSize: sourceUrl(size: LARGE)
        }
      }
    }
  }
`;
