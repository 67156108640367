import React from 'react';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import FinderSidebar from './FinderSidebar';
import CategoryPage from './CategoryPage';
import { useHeaderTitle } from './HeaderTitleContext';
import useDocumentTitle from '../hooks/useDocumentTitle';

const Finder: React.FC = () => {
	const { headerTitle } = useHeaderTitle();
	useDocumentTitle();

	return (
		<Draggable
			handle="#finder__header, #finder__footer"
			defaultPosition={{ x: 0, y: 0 }}
			grid={[1, 1]}
			scale={1}
			allowAnyClick={false}
			disabled={false}
			enableUserSelectHack={true}
			cancel="input, button"
		>
			<div id="finder">
				<header className='flex justify-between items-center min-w-full bg-slate-300' id="finder__header">
					<h1 className="order-2" id="finder__header-title">
						{headerTitle}
					</h1>
					<div className='order-1' id="finder__header-controls">
						<Link to='/' className='glossy-button glossy-button--red' id="finder__close">
							Close
						</Link>
						<button className='glossy-button glossy-button--gold' id="finder__minimize">Minimize</button>
						<button className='glossy-button glossy-button--green' id="finder__maximize">Maximize</button>
					</div>
					<div className="order-3" id="finder__header-search">
						<input type="search" className='max-w-full' id="finder__search-input" />
						<button className="hidden" id="finder__search-button">Search</button>
					</div>
				</header>
				<div className="flex" id="finder__content">
					<FinderSidebar />
					<div className="flex overflow-x-auto overflow-y-hidden" id="finder__main">
						<CategoryPage />
					</div>
				</div>
				<footer id="finder__footer">
					<div id="footer__text"></div>
					<p className='hidden'>
						© {new Date().getFullYear()} Fatima Al Qadiri
					</p>
				</footer>
			</div>
		</Draggable>
	);
}

export default Finder;