import React, { createContext, useContext, useState } from 'react';

const HeaderTitleContext = createContext({
  headerTitle: 'Fatima Al Qadiri',
  setHeaderTitle: (title: string) => {},
});

interface HeaderTitleContextProps {
	children: React.ReactNode;
}

export const useHeaderTitle = () => useContext(HeaderTitleContext);

export const HeaderTitleProvider: React.FC<HeaderTitleContextProps> = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState('Fatima Al Qadiri'); // Default title as initial state

  return (
    <HeaderTitleContext.Provider value={{ headerTitle, setHeaderTitle }}>
      {children}
    </HeaderTitleContext.Provider>
  );
};

export default HeaderTitleContext;
