import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Finder from './Finder';
import HomePage from './HomePage';
import CategoryPage from './CategoryPage';
import WallpaperBackground from './WallpaperBackground';
import { CategoryData, QueryData } from '../types/types';
import { GET_DEEP_CATEGORY_BY_SLUG } from '../graphql/categoriesQuery';

const Launcher: React.FC = () => {
  // We will always use the slug "music" first on launch.
  const topLevelSlug = "music"; // Placeholder slug

  // Prefetch that category data
  const { data, loading, error } = useQuery<QueryData, { slug: string }>(GET_DEEP_CATEGORY_BY_SLUG, {
    variables: { slug: topLevelSlug },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    const handleResize = () => {
      const launcherElement = document.getElementById('launcher');
      if (launcherElement) {
        if (window.innerHeight > window.innerWidth) {
          launcherElement.style.transform = 'rotate(90deg) translateX(-50%) translateY(-50%)';
               } else {
          launcherElement.style.transform = 'none';
        }
      }
    };

    handleResize(); // Initial handling on component mount
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex" id="launcher">
      <WallpaperBackground />
      <Router>    
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/*" element={<Finder />}>
            <Route path="contact" element={<div>Contact Page</div>} />
            <Route path="*" element={<CategoryPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default Launcher;
