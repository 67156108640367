import React from 'react';
import { useNavigate } from 'react-router-dom';
const hardDriveIcon = require('../images/icons/hard-drive-160x160.png');

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const navigateToMusic = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default anchor behavior
    navigate('/music/'); // Navigate to the /music/ path using React Router
  };

  return (
      <header id="hard_drive">
        <h1 className='font-bold'>
          <a href="/" onClick={navigateToMusic}>
            <img src={hardDriveIcon} alt="Mac OS hard drive icon" width="80" height="80" />
            Fatima Al Qadiri
          </a>
        </h1>
      </header>
  );
};

export default HomePage;
