import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import usePathSegments from '../hooks/usePathSegments';
import { useHeaderTitle } from './HeaderTitleContext';


const FinderSidebar: React.FC = () => {
	const { topLevelSlug } = usePathSegments(); // Use the hook
	const { setHeaderTitle } = useHeaderTitle();

	const favorites = {
		'Music': {
			'slug': 'music',
			'url': '/music/'
		},
		'Visual': {
			'slug': 'visual',
			'url': '/visual/'
		},
		'Contact': {
			'slug': 'contact',
			'url': 'mailto:info@fatimaalqadiri.com'
		}
	};

	// State hooks for managing open state of collapsibles
  const [isFavoritesOpen, setIsFavoritesOpen] = useState(true);
  const [isDevicesOpen, setIsDevicesOpen] = useState(true);

  // Handler functions to toggle open state
  const toggleFavorites = () => setIsFavoritesOpen(!isFavoritesOpen);
  const toggleDevices = () => setIsDevicesOpen(!isDevicesOpen);

	/* Loop through the favorites, 
		 ouput list items, and check if the path is active
	*/
	const renderFavorites = () => {
    return Object.entries(favorites).map(([name, {slug, url}]) => {
        const isActive = topLevelSlug === slug;

        // Check if the URL is an external link or a mailto link
        const isExternal = url.startsWith('http') || url.startsWith('mailto:');

        return (
            <li key={slug} id={`favorites_${slug}`} className={isActive ? 'active' : ''}>
                {isExternal ? (
                    // Render as a regular anchor tag for external URLs
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {name}
                    </a>
                ) : (
                    // Render as a Link component for internal navigation
                    <Link to={url} onClick={() => setHeaderTitle(name)}>
                        {name}
                    </Link>
                )}
            </li>
        );
    });
	};



	const deviceLinks = {
		'Website': '/',
		'Spotify': 'https://open.spotify.com/artist/1pfunEyqsC4oKSBY9gr8lN?si=R-zXXJPrRO29gnnIwMtwZw',
		'iTunes': 'https://music.apple.com/artist/fatima-al-qadiri/471458548',
		'Twitter': 'https://twitter.com/fatimaalqadiri',
		'SoundCloud': 'https://soundcloud.com/fatima-al-qadiri',
		'Facebook': 'https://facebook.com/FatimaAlQadiri',
		'Global .Wav': 'https://dismagazine.com/blog/global-wav/',
		
	};
	
	// Function to loop through the device links, ouput list items, and check if the path is active
	const renderDeviceLinks = () => {
    return Object.entries(deviceLinks).map(([name, url]) => {
        // Check if the URL is an external link
        const isExternal = url.startsWith('http') || url.startsWith('mailto:');
        
        if (isExternal) {
            return (
                <li key={name} id={`device_${name.toLowerCase().replace(/(\s|\.)/g, "")}`}>
                    <a href={url} target="_blank" rel="noopener noreferrer">{name}</a> {/* Use <a> for external links */}
                </li>
            );
        } else {
            // For internal links, continue using Link from react-router-dom
            return (
                <li key={name} id={`device_${name.toLowerCase().replace(/(\s|\.)/g, "")}`}>
                    <Link to={url}>{name}</Link>
                </li>
            );
        }
    });
	};


	return (
		<nav className="flex-none" id="menu">
			<ul>
				<li>
					<Collapsible trigger="Favorites" triggerTagName="h4"  triggerOpenedClassName="collapsible__active" transitionTime={200} open={isFavoritesOpen}
            onTriggerOpening={toggleFavorites}
            onTriggerClosing={toggleFavorites}>
						<ul>
							{renderFavorites()}
						</ul>
					</Collapsible>
				</li>
				<li>
					<Collapsible trigger="Devices" triggerTagName="h4"  triggerOpenedClassName="collapsible__active" transitionTime={200} open={isDevicesOpen}
            onTriggerOpening={toggleDevices}
            onTriggerClosing={toggleDevices}>
						<ul>
							{renderDeviceLinks()}
						</ul>
					</Collapsible>
				</li>
			</ul>
		</nav>
	);
} 

export default FinderSidebar;